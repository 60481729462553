/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import httpClient from '../../api/httpClient';

/* -------------------------------------------------------------------------- */
/*                                  Services                                  */
/* -------------------------------------------------------------------------- */

class ProductServices {
  // Scan
  static scan = async (reference) => {
    const apiResponse = await httpClient.get(`/products/scan/${reference}`);

    // Return product data
    return apiResponse.data;
  };

  // Verify
  static verify = async (reference) => {
    const apiResponse = await httpClient.get(`/products/verify/${reference}`);

    // Return product data
    return apiResponse.data;
  };

  // Text Recognition
  static textRecognition = async (data) => {
    const apiResponse = await httpClient.post(`/detect-text`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Return text detected from google api vision
    return apiResponse.data;
  };
}

export default ProductServices;
